<template>
    <v-app
        class="main-background"
        v-bind:class="{ 'mobile-background': responsive }"
    >
        <!-- <v-toolbar class="header" style="background-color: rgb(163 226 161)">
            <v-layout justify-end class="pt-3">
                <v-btn dark class="mb-3" color="rgb(40 155 44)" @click="logOut"
                    >Log Out</v-btn
                >
            </v-layout>
        </v-toolbar> -->

        <v-toolbar
            class="header"
            flat
            style="background-color: rgb(163 226 161)"
        >
            <v-btn
                dark
                class="my-2"
                color="rgb(40 155 44)"
                v-if="showAdminSettings()"
                to="/admin-settings"
                >Admin Settings</v-btn
            >
            <v-flex xs5 md10></v-flex>
            <v-layout justify-center align-center>
                <v-btn
                    dark
                    class="my-2 mr-3"
                    color="rgb(40 155 44)"
                    v-if="showLogOut()"
                    @click="logOut"
                    >Log Out</v-btn
                >
                <v-divider
                    vertical
                    class="py-0"
                    style="color: #fff; border: 0.5px solid"
                ></v-divider>
                <div class="bsd ml-3 white--text">בס"ד</div>
            </v-layout>
        </v-toolbar>
        <router-view
            v-bind:class="{
                'width-960': !isAdminSettings(),
                'full-width': isAdminSettings(),
            }"
            style="z-index: 1"
        ></router-view>
    </v-app>
</template>

<script>
export default {
    name: "App",

    data: () => ({
        //
        responsive: false,
    }),
    mounted() {
        this.onResponsiveInverted();
        window.addEventListener("resize", this.onResponsiveInverted);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResponsiveInverted);
    },
    destroyed() {
        window.removeEventListener("keyup", (e) => this.onKeyPressed(e));
    },
    methods: {
        onResponsiveInverted() {
            if (window.innerWidth < 991) {
                this.responsive = true;
            } else {
                this.responsive = false;
            }
        },
        logOut() {
            this.$router.push({
                name: "Login",
            });
        },
        showLogOut() {
            return (
                window.location.pathname != "/login" &&
                !window.location.pathname.includes("/set-password")
            );
        },
        showAdminSettings() {
            if (this.$store.state.user != "yw2086@gmail.com") return;

            return (
                window.location.pathname != "/login" &&
                window.location.pathname != "/admin-settings" &&
                !window.location.pathname.includes("/set-password")
            );
        },
        isAdminSettings() {
            return window.location.pathname == "/admin-settings";
        },
    },
};
</script>
<style>
html,
body {
    margin: 0;
    padding: 0;
}
.main-background {
    /*background: #bdbdbd !important;*/
    background-image: linear-gradient(
            to bottom,
            rgba(245, 246, 252, 0.52),
            rgb(212 255 210 / 73%)
        ),
        url(https://broadcastmessages.blob.core.windows.net/images/grand_canyon_sunrise.jpg) !important;
    background-size: cover !important;
    color: white !important;
}
.mobile-background {
    min-width: 475px;
}
.main-footer {
    background-color: transparent !important;
}
.main-background:after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 30%;
    /*background: #bdbdbd !important;*/
    z-index: 0;
}
.header {
    z-index: 2 !important;
    background-color: unset !important;
    box-shadow: none !important;
    max-height: 64px;
}
.width-960 {
    width: 960px !important;
}
.full-width {
    width: 1600px !important;
}
</style>
