export default {
    ["AUTH_REQUEST"]: (state) => {
        state.status = "loading";
    },
    ["AUTH_SUCCESS"]: (
        state,
        { token, user, name, roles, claims, isAdmin }
    ) => {
        state.status = "success";
        state.token = token;
        state.user = user;
        state.name = name;
        state.roles = roles;
        state.claims = claims;
        state.isAdmin = isAdmin;
    },
    ["AUTH_LOGOUT"]: (state) => {
        state.status = "";
        state.token = "";
    },
    ["AUTH_ERROR"]: (state) => {
        state.status = "error";
    },
};
