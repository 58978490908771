/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
    {
        path: "/login",
        name: "Login",
        view: "Login",
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        view: "Dashboard",
    },
    {
        path: "/set-password/:id",
        name: "SetPassword",
        view: "SetPassword",
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        view: "ForgotPassword",
    },
    {
        path: "/admin-settings",
        name: "AdminSettings",
        view: "AdminSettings",
    },
    {
        path: "/test",
        name: "Test",
        view: "Test",
    },
    {
        path: "/hello-world",
        name: "HelloWorld",
        view: "HelloWorld",
    },
    {
        path: "/confirm-payment",
        name: "ConfirmPayment",
        view: "ConfirmPayment",
    },
    {
        path: "/payment-result",
        name: "PaymentResult",
        view: "PaymentResult",
    },
];
