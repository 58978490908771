import Vue from "vue";
import App from "./App.vue";
import { sync } from "vuex-router-sync";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import LogRocket from "logrocket";
import vuetify from "./plugins/vuetify";

sync(store, router);

Vue.config.productionTip = false;

LogRocket.init("myrumg/jtravel");

const instance = axios.create({
    baseURL: window.location.origin.startsWith("http://localhost")
        ? "https://localhost:44368/"
        : "https://wercbergerproductions1-kha7mhdahq-ue.a.run.app/",
});

function errorHandler(error) {
    if (error.response && error.response.status == 401) {
        window.location.href = "/login";

        return;
    }

    const text =
        error.response.data && error.response.data.Message
            ? error.response.data.Message
            : "An error has occurred";

    throw new Error(text);
}

instance.interceptors.request.use(function (config) {
    config.headers.Authorization = store.state.token;

    return config;
});
instance.interceptors.response.use((response) => {
    return response ? response.data : response;
}, errorHandler);

Vue.prototype.$http = instance;

new Vue({
    router,
    vuetify,
    store,
    render: (h) => h(App),
}).$mount("#app");
