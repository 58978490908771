// https://vuex.vuejs.org/en/actions.html
import axios from "axios";
/* eslint-disable */

export default {
    AUTH_REQUEST({ commit, dispatch }, user) {
        return new Promise((resolve, reject) => {
            commit("AUTH_REQUEST");

            const baseUrl = window.location.origin.startsWith(
                "http://localhost"
            )
                ? "https://localhost:44368/"
                : "https://wercbergerproductions1-kha7mhdahq-ue.a.run.app/";

            const url = baseUrl + "api/login/login?";

            axios
                .post(url, {
                    username: user.username,
                    password: user.password,
                    ipAddress: user.ipAddress,
                })
                .then((resp) => {
                    const response = resp.data;

                    const token = "Bearer " + response.token;

                    localStorage.setItem("user-token", token);
                    localStorage.setItem("user-username", user.username);
                    localStorage.setItem("user-name", response.name);
                    localStorage.setItem("user-roles", response.roles);
                    localStorage.setItem("user-claims", response.claims);
                    localStorage.setItem("user-isAdmin", response.isAdmin);

                    commit("AUTH_SUCCESS", {
                        token: token,
                        user: user.username,
                        name: response.name,
                        roles: response.roles,
                        claims: response.claims,
                        isAdmin: response.isAdmin,
                    });

                    resolve(response);
                })
                .catch((err) => {
                    commit("AUTH_ERROR", err);

                    localStorage.removeItem("user-token");
                    localStorage.removeItem("user-roles");
                    localStorage.removeItem("user-username");
                    localStorage.removeItem("user-name");
                    localStorage.removeItem("user-claims");

                    let errorMessage = err;

                    if (err.response.data && err.response.data.Message) {
                        errorMessage = err.response.data.Message;
                    }

                    reject(errorMessage);
                });
        });
    },
    AUTH_LOGOUT({ commit }) {
        return new Promise((resolve) => {
            localStorage.removeItem("user-token");
            localStorage.removeItem("user-roles");
            localStorage.removeItem("user-claims");
            localStorage.removeItem("user-username");
            localStorage.removeItem("user-name");

            commit("AUTH_LOGOUT");

            resolve();
        });
    },
};
