// https://vuex.vuejs.org/en/state.html

export default {
    token: localStorage.getItem("user-token") || "",
    claims: localStorage.getItem("user-claims") || "",
    roles: localStorage.getItem("user-roles") || "",
    user: localStorage.getItem("user-username") || "",
    name: localStorage.getItem("user-name") || "",
    isAdmin: localStorage.getItem("user-isAdmin") || "",
    status: "",
};
